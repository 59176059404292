.nav {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  padding-left: 4%;
  padding-right: 4%;
  width: 100%;

  .logo {
    font-family: "Kaushan Script", cursive;
    color: #f48769;
    display: flex;
    align-self: center;
    font-size: 2.9rem;
    font-weight: 300;
    transform: rotate(-15deg);
    overflow: hidden;
    background: linear-gradient(to right, #f48769, #f48769 50%, #ffffff 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 500% 100%;
    background-position: 100%;
    transition: background-position 700ms ease;
    padding-right: 0.8rem;
    margin-right: -0.8rem;
    padding-bottom: 1rem;
    margin-bottom: -0.7rem;

    &:hover {
      background-position: 0 100%;
    }
  }

  @media screen and (min-width: 400px) {
    .logo {
      padding-right: 2rem;
      margin-right: -2rem;
      padding-bottom: 1rem;
      margin-bottom: -0.7rem;
    }
  }
  a {
    text-decoration: none;
  }

  .navigation-bar {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 5.2rem;
    background-color: #3a3943;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: flex-end;
      width: 28%;
      height: 100%;

      @media screen and (max-width: 765px) {
        width: 40%;
      }

      @media screen and (max-width: 576px) {
        width: 50%;
      }

      @media screen and (max-width: 420px) {
        width: 75%;
      }

      li {
        font-family: "Roboto";
        font-weight: 400;
        text-align: center;
        font-size: 2.1rem;
        color: #ffffff;

        &:hover {
          color: #f48769;
        }
      }
    }
  }
}
