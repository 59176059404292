.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .contact-h2 {
    font-size: 3.6rem;
    line-height: 4.2rem;
    margin-bottom: 7.2rem;
  }

  .form-container {
    display: flex;
    flex-direction: column;

    .form {
      color: #ffffff;
      display: flex;
      flex-direction: column;

      .contact-error {
        font-size: 1.6rem;
        color: #f48769;
        margin: -2rem 0 1rem 0;
        align-self: center;
      }

      .input-label {
        display: flex;
        flex-direction: column;
        font-size: 2.4rem;

        input {
          background: none;
          width: 85.6rem;
          height: 4.6rem;
          border: 2px solid #3d758a;
          border-radius: 0.7rem;
          margin: 0.7rem 0 3.2rem 0;
          font-size: 1.8rem;
          color: #ffffff;
          padding-left: 1rem;
          &::placeholder {
            color: red;
            opacity: 1;
          }

          &:focus {
            border: 2px solid #f48769;
            border-radius: 0.7rem;
            outline: none;
            
          }
        }

        .input-textarea {
          color: #ffffff;
          margin-top: 0.7rem;
          background: none;
          width: 85.6rem;
          height: 27.5rem;
          border: 2px solid #3d758a;
          border-radius: 0.7rem;
          resize: none;
          font-size: 1.8rem;
          padding-left: 1rem;
          padding-top: 1rem;
          font-family: "roboto";

          &:focus {
            border: 2px solid #f48769;
            border-radius: 0.7rem;
            outline: none;
          }
        }
      }

      .success-message {
        display: flex;
        justify-self: center;
        align-self: center;
        font-size: 1.8rem;
      }

      .contact-disabled {
        margin-top: 4.8rem;
        margin-bottom: 13.2rem;
        height: 5.5rem;
        background: #213e49;
        font-size: 2.8rem;
        line-height: 3.2rem;
        border-radius: 0.7rem;
        border: 2px solid #213e49;
        color: #ffffffa1;

        &:hover {
          pointer-events: none;
        }
      }

      .contact-button {
        margin-top: 4.8rem;
        margin-bottom: 13.2rem;
        height: 5.5rem;
        background: #3d758a;
        font-size: 2.8rem;
        line-height: 3.2rem;
        border-radius: 0.7rem;
        border: 2px solid #3d758a;
        color: #ffffff;

        &:hover {
          border: 2px solid #ffffff;
        }
      }
    }
  }
  @media screen and (max-width: 765px) {
    .form-container {
      width: 85%;

      .form {
        .input-label {
          input,
          .input-textarea {
            width: 100%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 420px) {
    .form-container {
      width: 95%;

      .form {
        .input-label {
          input,
          .input-textarea {
            width: 100%;
          }
        }
      }
    }
  }
}
