html{
  width: 100%;
}

body {
  background-color: #3a3943;
}

.app {
  font-size: 62.5%;
}


