.image-div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 90vh;
  position: relative;
  background: black;

  .hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.55;
    overflow: hidden;

    @media screen and (max-width: 765px) {
      width: 100%;
    }
  }
  .headshot {
    display: flex;
    position: absolute;
    width: 27.5%;
    left: 69%;
    bottom: 38%;
    border-radius: 5%;
    align-self: center;
    border: 2px solid #f48769;
  }

  @media screen and (max-width: 992px) {
    .headshot {
      display: none;
    }
  }
}

.hero-text {
  margin: 0;
  margin: 2% 0 5% 0;
  margin-left: 4%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  height: 25%;
  position: absolute;

  @media screen and (max-width: 992px) {
    width: 85%;
  }

  @media screen and (max-width: 576px) {
    width: 95%;
  }


  h1 {
    font-family: "Roboto";
    font-size: 6.4rem;
    line-height: 7.5rem;
    font-weight: 500;
    color: #ffffff;

    @media screen and (max-width: 992px) {
      font-size: 6.8rem;
    }
    @media screen and (max-width: 765px) {
      font-size: 6.2rem;
    }

    @media screen and (max-width: 420px) {
      font-size: 4.5rem;
      line-height: 5.5rem;
    }
  }
}

.about-div {
  margin-top: 7.2rem;
  .about-text {
    font-family: "Roboto";
    font-weight: 300;
    font-size: 2.8rem;
    line-height: 3.3rem;
    color: #ffffff;

    @media screen and (max-width: 992px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 765px) {
      font-size: 2.6rem;
    }

    @media screen and (max-width: 420px) {
      font-size: 2.4rem;
      line-height: 3.1rem;
    }
  }
}
