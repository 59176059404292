.pagination-nav {
  justify-content: center;
  width: 50%;
  margin-bottom: 13.2rem;
  margin-top: -7rem;
  justify-self: center;

  .pagination {
    height: 5rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    position: relative;
    align-items: center;

    .arrows {
      display: flex;
      position: absolute;
      font-size: 4.5rem;
      width: 20%;
      align-items: center;
      align-self: center;
      justify-content: space-between;

      a {
        color: #000000;

        text-decoration: none;

        &:visited {
          color: #000000;
        }
        &:hover {
          color: #f48769;
        }
      }

      .disabled {
        pointer-events: none;
        cursor: default;
        color: #7b7b7b;

        &:visited {
          color: #7b7b7b;
        }
      }
    }

    @media screen and (max-width: 420px) {
      .arrows {
        width: 100%;
      }
    }

    li {
      div {
        width: 0.8rem;
        height: 0.8rem;
        border: 1px solid #7b7b7b;
        border-radius: 50%;
        background: #7b7b7b;
        margin: 1rem;
      }

      .active {
        width: 1.2rem;
        height: 1.2rem;
        border: 2px solid #f48769;

        border-radius: 50%;
        background: #000000;
      }
    }
  }
}
