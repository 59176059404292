html,
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-size: 62.5%;
  font-family: "Roboto";
}

.light-mode {
  color: #ffffff;
}
