@import "variables.scss";

.test-div {
  display: flex;
  justify-content: center;

  .project-card-container {
    font-family: "Roboto";

    display: flex;
    flex-direction: column;
    width: 75%;
    height: 62rem;
    margin-bottom: 12.2rem;
    background: #14131a;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;

    &:nth-of-type(3n) {
      margin-bottom: 0;
    }
    .project-card-image {
      display: flex;
      justify-content: center;
      height: 22rem;
      width: 100%;
      margin-bottom: 4.8rem;

      h2 {
        align-self: center;
        font-size: 6rem;
        color: #ffffff;
        position: absolute;
        z-index: 10;
        text-shadow: 2px 2px #14131a;
      }

      @media screen and (max-width: 992px) {
        h2 {
          font-size: 5rem;
        }
      }

      @media screen and (max-width: 420px) {
        h2 {
          font-size: 3rem;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        opacity: 0.65;
      }
    }

    .project-card-text {
      color: #ffffff;
      margin-bottom: 4.8rem;
      margin-left: 4%;

      h3 {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 0.7rem;
      }
      p {
        font-size: 2.1rem;
        line-height: 2.5rem;
      }

      @media screen and (max-width: 992px) {
        p {
          font-size: 2rem;
        }
      }

      @media screen and (max-width: 765px) {
        p {
          font-size: 1.8rem;
          width: 95%;
        }
      }
    }

    .project-card-tech-stack {
      margin-left: 4%;
      margin-bottom: 4.8rem;
      color: #ffffff;

      h3 {
        font-size: 2.4rem;
        line-height: 2.8rem;
        margin-bottom: 0.7rem;
      }
      p {
        font-size: 2.1rem;
        line-height: 2.5rem;
      }
      @media screen and (max-width: 992px) {
        p {
          font-size: 2rem;
        }
      }

      @media screen and (max-width: 765px) {
        p {
          font-size: 1.8rem;
          width: 95%;
        }
      }
    }

    .project-card-buttons {
      display: flex;
      justify-content: space-around;

      .button-primary {
        width: 47.2rem;
        height: 5.5rem;
        background: #3d758a;
        font-size: 2.8rem;
        line-height: 3.3rem;
        border: 2px solid #3d758a;
        border-radius: 0.7rem;
        color: #ffffff;

        &:hover {
          border: 2px solid #ffffff;
        }
      }

      .button-secondary {
        width: 47.2rem;
        height: 5.5rem;
        font-size: 2.8rem;
        line-height: 3.3rem;
        background: none;
        border: 2px solid #f48769;
        border-radius: 0.7rem;
        color: #f48769;

        &:hover {
          border: 2px solid #ffffff;
        }
      }
      @media screen and (max-width: 992px) {
        .button-primary,
        .button-secondary {
          width: 37rem;
        }
      }

      @media screen and (max-width: 765px) {
        .button-primary,
        .button-secondary {
          width: 30rem;
        }
      }

      @media screen and (max-width: 576px) {
        .button-primary,
        .button-secondary {
          width: 23rem;
        }
      }

      @media screen and (max-width: 420px) {
        .button-primary,
        .button-secondary {
          width: 15rem;
        }
      }
    }
  }
  @media screen and (max-width: 992px) {
    .project-card-container {
      width: 85%;
      min-height: 70rem;
    }
  }

  @media screen and (max-width: 765px) {
    .project-card-container {
      width: 90%;
    }
  }
}
