@import "reset.scss";
@import "global.scss";
@import "variables.scss";
@import "../../App.scss";
@import "main.scss";
@import "nav.scss";
@import "hero.scss";
@import "skills.scss";
@import "project-card.scss";
@import "projects.scss";
@import "pagination.scss";
@import "contact.scss";
@import "footer.scss";

@font-face {
  font-family: "Roboto", sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap");
}
