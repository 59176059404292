.footer-container {
  margin-top: 3rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: #ffffff;
    font-size: 1.8rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;

    a {
      color: #3d758a;
    }
  }
}
