@import "variables.scss";

.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .skills-h2 {
    font-family: "Roboto";
    margin-top: 7.2rem;
    font-size: 3.6rem;
    line-height: 4.2rem;
  }

  ul {
    margin-top: 3.2rem;
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-between;

    margin-bottom: 7.2rem;
    .skills-item {
      font-family: "Roboto";
      font-weight: 400;
      font-size: 2.8rem;
      line-height: 3.3rem;
      text-align: center;
    }
  }
}

@media screen and (min-width: 1600px) {
  .skills-container {
    ul {
      width: 50%;

      .skills-item {
        font-size: 3.5rem;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .skills-container {
    ul {
      width: 85%;
    }

    .skills-item {
      font-size: 3rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .skills-container {
    ul {
      width: 90%;
    }

    .skills-item {
      font-size: 2.6rem;
    }
  }
}

@media screen and (max-width: 765px) {
  .skills-container {
    ul {
      width: 95%;

      .skills-item {
        font-size: 2.4rem;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .skills-container {
    ul {
      flex-wrap: wrap;
      height: 10rem;
      width: 75%;
      justify-content: space-around;

      .skills-item {
        font-size: 2.6rem;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .skills-container {
    ul {
      flex-wrap: wrap;
      height: 10rem;
      width: 95%;
      justify-content: space-around;

      .skills-item {
        font-size: 2.6rem;
      }
    }
  }
}
