.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .projects-h2 {
    font-size: 3.6rem;
    line-height: 4.2rem;
    margin-bottom: 8.2rem;
  }

  .loading {
    justify-content: center;
    align-self: center;
    font-size: 3.6rem;
  }
}
